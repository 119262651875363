import { makeStyles } from '@material-ui/styles';

const useHeaderStyle = makeStyles(theme => ({
  h2: props => ({
    fontSize: '64px',
    color: '#01153D',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      fontFamily: 'Inter'
    },
    ...props
  }),
  h3: props => ({
    fontSize: '52px',
    color: '#01153D',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      fontFamily: 'Inter'
    },
    ...props
  })
}));

export default useHeaderStyle;
