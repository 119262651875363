import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useHeaderStyle from '~styles/useHeaderStyle';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    backgroundColor: '#FFFFFF',
    gap: 15,
    position: 'relative'
  },
  media: {
    height: 140,
    [theme.breakpoints.down('xs')]: {
      height: 100
    }
  },
  featureTitle: {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 600,
    color: '#000000',
    textAlign: 'center',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Inter',
      marginTop: '15px'
    }
  },
  featureDescription: {
    fontSize: '16px',
    lineHeight: '23px',
    color: '#000000',
    textAlign: 'center',
    marginTop: '8px',
    maxWidth: '207px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Inter',
      marginTop: '4px'
    }
  }
}));

const LearningMethods = ({ title, methods, bottom_shape_divider }) => {
  const classes = useStyles();
  const headerStyle = useHeaderStyle();

  return (
    <div className={classes.container}>
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gridGap="57px"
        >
          <Typography variant="h2" className={headerStyle.h2}>
            {title}
          </Typography>
          <Grid container spacing={3}>
            {methods.map(method => (
              <Grid key={method?.title} item xs={6} sm={6} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  marginBottom="25px"
                >
                  <img
                    src={method?.thumbnail?.url}
                    alt={method?.thumbnail?.alt}
                    className={classes.media}
                  />
                  <Typography variant="h5" component="h2" className={classes.featureTitle}>
                    {method?.title}
                  </Typography>
                  <Typography variant="body2" component="p" className={classes.featureDescription}>
                    {method?.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

LearningMethods.propTypes = {
  methods: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      thumbnail: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      })
    })
  ),
  title: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

LearningMethods.defaultProps = {
  title: '',
  methods: [],
  bottom_shape_divider: null
};

export default LearningMethods;
